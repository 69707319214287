import {loginPage, forgotPasswordPage, authenticationEmailPage, publicPage, userPage, ordersPage, priceListPage, basketPage, mainPage} from './pages';
import { serverUrl } from '../config';

const USER_URL = serverUrl + userPage;
const PUBLIC_URL = serverUrl + publicPage;
const ORDER_URL = serverUrl + ordersPage;
const BASKET_URL = serverUrl + basketPage;
const PRICELIST_URL = serverUrl + priceListPage;

export const PUBLIC_URLS = {
    LOGIN: PUBLIC_URL + loginPage,
    FORGOT_PASSWORD: PUBLIC_URL + forgotPasswordPage,
    AUTHENTICATION_EMAIL: PUBLIC_URL + authenticationEmailPage
};

export const USERS_URLS = {
    FIND_BY_ID: USER_URL + "/",
    FIND_ALL: USER_URL + "/list",
    CREATE: USER_URL + '/create',
    UPDATE: USER_URL + '/update/',
    DELETE: USER_URL + '/delete/',
    CHANGE_PASSWORD: USER_URL + '/change-password'
};


export const ORDERS_URL = {
    FIND_ALL_BY_USER_ID: ORDER_URL + '/list',
}

export const BASKETS_URL = {
    FIND_ALL_BY_USER_ID: BASKET_URL + '/list',
    UPDATE: BASKET_URL + '/update',
    DELETE: BASKET_URL + '/delete',
    CONFIRM: BASKET_URL + '/confirm',
    COPY_ORDER_TO_BASKET: BASKET_URL + '/copy',
    GET_COUNT_OF_PRODUCT_IN_BASKET: BASKET_URL + '/count',
    SYNCHRONIZATION: BASKET_URL + '/synchronization',
}

export const PRICELISTS_URL = {
    FIND_ALL_BY_USER_ID: PRICELIST_URL + '/list',
    GET_FILTERS_BY_PL_CODE: PRICELIST_URL + '/filters',
    GET_FILTERS_ARRAY_BY_PL_CODE: PRICELIST_URL + '/filters-array',
    ORDERED_BY_CATEGORIES_BY_CUST_CODE: PRICELIST_URL + '/order-by-category-sum',
}




