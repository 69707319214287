import { getData, postData } from "../lib/fetchApi";
import { BASKETS_URL } from "../constants/urls";
import { defaultLocale } from "../config";

const basketService = {

    findAllByUserId: (user, lang) => {
        return getData({ url: BASKETS_URL.FIND_ALL_BY_USER_ID, lang });
    },
    updateBasket: (data) => {
        return postData({ url: BASKETS_URL.UPDATE, data });
    },
    deleteBasket: (data) => {
        return postData({ url: BASKETS_URL.DELETE, data });
    },
    confirmBasket: (data) => {
        return postData({ url: BASKETS_URL.CONFIRM, data });
    },
    copyOrderToBasket: (data) => {
        return postData({ url: BASKETS_URL.COPY_ORDER_TO_BASKET, data });
    },
    getCountOfProductInBasket: (data) => {
        return getData({ url: BASKETS_URL.GET_COUNT_OF_PRODUCT_IN_BASKET, data });
    },
    basketSynchronization: (data) => {
        return postData({ url: BASKETS_URL.SYNCHRONIZATION, data });
    },
}
export default basketService;
